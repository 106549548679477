import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-ambassador-api-gateway"
    }}>{`Setup Ambassador API Gateway`}</h1>
    <p>{`In order to integrate Ocelot with Ambassador API Gateway, we make usage of `}<inlineCode parentName="p">{`Filter`}</inlineCode>{`, `}<inlineCode parentName="p">{`FilterPolicy`}</inlineCode>{` and `}<inlineCode parentName="p">{`Mappings`}</inlineCode>{`.
If you are not familiar with these concepts, we advise to visit the
`}<a parentName="p" {...{
        "href": "https://www.getambassador.io/docs/edge-stack/latest/topics/using/filters/"
      }}>{`Ambassador documentation site`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`apiVersion: getambassador.io/v2
kind: Filter
metadata:
  name: ocelot-auth
  namespace: security
spec:
  External:
    auth_service: "ocelot.<NAMESPACE>.svc:8080"
    timeout_ms: 5000
    include_body:
      max_bytes: 1024000
      allow_partial: true
    status_on_error:
      code: 500
    allowed_request_headers:
      - "x-clientid"
      - "x-clientsecret"
      - "origin"
    allowed_authorization_headers:
      - "x-jwt"
    failure_mode_allow: false
---
apiVersion: getambassador.io/v2
kind: FilterPolicy
metadata:
  name: ocelot-policy
  namespace: security
spec:
  rules:
    - host: <API_HOST>
      path: /ocelot/*
      filters: null
    - host: <API_HOST>
      path: /*
      filters:
        - name: ocelot-auth
---
apiVersion: getambassador.io/v2
kind: Mapping
metadata:
  name: ocelot
  namespace: security
spec:
  host: <API_HOST>
  prefix: /ocelot/
  rewrite: /ocelot/
  service: ocelot.security.svc:8080
`}</code></pre>
    <h1 {...{
      "id": "setup-tyk-api-gateway"
    }}>{`Setup Tyk API Gateway`}</h1>
    <p>{`In order to integrate Ocelot with Tyk API Gateway, we integrate through a rich plugin gRPC api.
If you are not familiar with these concepts, we advise to visit the
`}<a parentName="p" {...{
        "href": "https://tyk.io/docs/plugins/supported-languages/rich-plugins/grpc/"
      }}>{`Tyk documentation site`}</a>{`.`}</p>
    <p>{`Just add in your gateway configuration the following snippet:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
  "coprocess_options": {
    "enable_coprocess": true,
    "coprocess_grpc_server": "tcp://ocelot:9090"
  },
...
`}</code></pre>
    <p>{`Complete samples are available on our `}<a parentName="p" {...{
        "href": "https://github.com/YanchWare/ocelot-samples"
      }}>{`github repo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      